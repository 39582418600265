@media (max-width: 800px) {
  .about-container {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .about-box {
    flex-basis: 100%;
  }
  .about-box:last-child {
    margin-bottom: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
  }
  .portfolio-box {
    flex-basis: 100%;
    max-height: 80%;
    margin-bottom: 10px;
  }
}
